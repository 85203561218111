import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Category() {
    const category = [
        {
            id: 0,
            image: "https://media.istockphoto.com/id/1829241109/photo/enjoying-a-brunch-together.jpg?b=1&s=612x612&w=0&k=20&c=Mn_EPBAGwtzh5K6VyfDmd7Q5eJFXSHhGWVr3T4WDQRo=",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 1,
            image: "https://www.cnet.com/a/img/resize/69256d2623afcbaa911f08edc45fb2d3f6a8e172/hub/2023/02/03/afedd3ee-671d-4189-bf39-4f312248fb27/gettyimages-1042132904.jpg?auto=webp&fit=crop&height=675&width=1200",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 2,
            image: "https://cdn.sanity.io/images/cctd4ker/production/9dfaeda73538877faf9c927ec8c8d6863c2c2111-4800x3200.jpg?w=3840&q=75&fit=clip&auto=format",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 3,
            image: "https://www.bione.in/wp-content/uploads/2024/07/best-fermented-food.jpg",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 4,
            image: "https://assets.telegraphindia.com/telegraph/2023/Jul/1690269945_1-68.jpg",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 5,
            image: "https://www.nehascookbook.com/wp-content/uploads/2022/09/Punjabi-thali-WS.jpg",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 6,
            image: "https://clubmahindra.gumlet.io/blog/images/Paneer-Bhurji-resized.jpg?w=376&dpr=2.6",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 7,
            image: "https://cdn0.weddingwire.in/article/4333/3_2/960/jpg/123334-maharaja-bhog-2.jpeg",
            title: "This Is Title",
            price: "784",
            fav: false
        },
    ];

    const categoryContainer = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const updateScrollStatus = () => {
        const container = categoryContainer.current;
        setCanScrollLeft(container.scrollLeft > 0);
        setCanScrollRight(container.scrollLeft < container.scrollWidth - container.clientWidth);
    };

    useEffect(() => {
        const container = categoryContainer.current;
        updateScrollStatus();
        container.addEventListener("scroll", updateScrollStatus);
        return () => container.removeEventListener("scroll", updateScrollStatus);
    }, []);

    const smoothScroll = (direction) => {
        const container = categoryContainer.current;
        const scrollAmount = 300 * direction;

        container.scrollBy({
            left: scrollAmount,
            behavior: "smooth"
        })
    };

    return (
        <div className="w-full pt-4 relative overflow-hidden">
            <h1 className="font-bold px-2">Categories</h1>
            <div className="overflow-hidden relative">
                <div className="flex flex-row w-full justify-start overflow-x-auto no-scrollbar md:px-8 lg:px-8"
                    ref={categoryContainer}>
                    {category.map((category, index) => (
                        <Link to={category.image} className="flex flex-col justify-center items-center m-2 relative">
                            <div className="flex flex-col justify-center w-full">
                                <img src={category.image} alt={category.title} className="w-28 md:w-36 max-w-48 object-cover aspect-square rounded-md"/>
                                <span className="text-gray-600 text-xs pt-1">{ category.title + " " + index }</span>
                            </div>
                        </Link>
                    ))}
                </div>
                <button
                    onClick={() => smoothScroll(-1)}
                    disabled={!canScrollLeft}
                    className={`hidden md:block lg:block absolute left-0 top-2/4 h-full transform -translate-y-1/2 pr-2 pl-1 bg-gradient-to-r from-[rgba(0,0,0,0.5)] to-transparent ${!canScrollLeft ? "cursor-not-allowed" : ""
                        }`}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className={`bg-white rounded-full p-2 border border-gray-500 ${!canScrollLeft ? "opacity-30" : ""
                        }`} />
                </button>
                <button
                    onClick={() => smoothScroll(1)}
                    disabled={!canScrollRight}
                    className={`hidden md:block lg:block absolute right-0 top-2/4 h-full transform -translate-y-1/2 pl-2 pr-1 bg-gradient-to-l from-[rgba(0,0,0,0.5)] to-transparent ${!canScrollRight ? "cursor-not-allowed" : ""
                        }`}
                >
                    <FontAwesomeIcon icon={faArrowRight} className={`bg-white rounded-full p-2 border border-gray-500 ${!canScrollRight ? "opacity-30" : ""
                    }`} />
                </button>
            </div>
        </div>
    );
}

export default Category;