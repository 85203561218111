import React from "react";

function Password({ handleLogin, input, setInput }) {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
            <span className="font-bold mb-6">Coming Soon</span>
            <form onSubmit={handleLogin} className="flex flex-col items-center justify-center">
                <input
                    type="password"
                    value={input}
                    onChange={(e) => { setInput(e.target.value) }}
                    placeholder="Enter Password"
                    className="p-2 outline-none focus:border border-gray-500 rounded mb-4 text-white bg-gray-800" />
                <button
                    type="submit"
                    className="py-2 px-8 bg-slate-700 rounded" >Submit</button>
            </form>
        </div>
    )
}

export default Password;