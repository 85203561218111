import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { Mousewheel, Keyboard, Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper/modules";

function Slider() {
    const slides = [
        {
            image: "https://media.istockphoto.com/id/1829241109/photo/enjoying-a-brunch-together.jpg?b=1&s=612x612&w=0&k=20&c=Mn_EPBAGwtzh5K6VyfDmd7Q5eJFXSHhGWVr3T4WDQRo="
        },
        {
            image: "https://www.cnet.com/a/img/resize/69256d2623afcbaa911f08edc45fb2d3f6a8e172/hub/2023/02/03/afedd3ee-671d-4189-bf39-4f312248fb27/gettyimages-1042132904.jpg?auto=webp&fit=crop&height=675&width=1200"
        },
        {
            image: "https://cdn.sanity.io/images/cctd4ker/production/9dfaeda73538877faf9c927ec8c8d6863c2c2111-4800x3200.jpg?w=3840&q=75&fit=clip&auto=format"
        },
        {
            image: "https://www.bione.in/wp-content/uploads/2024/07/best-fermented-food.jpg"
        },
        {
            image: "https://assets.telegraphindia.com/telegraph/2023/Jul/1690269945_1-68.jpg"
        },
        {
            image: "https://www.nehascookbook.com/wp-content/uploads/2022/09/Punjabi-thali-WS.jpg"
        },
        {
            image: "https://clubmahindra.gumlet.io/blog/images/Paneer-Bhurji-resized.jpg?w=376&dpr=2.6"
        },
        {
            image: "https://cdn0.weddingwire.in/article/4333/3_2/960/jpg/123334-maharaja-bhog-2.jpeg"
        },
    ];

    return (
        <div className="w-full mx-auto py-2">
            <Swiper
                effect={"coverflow"}
                centeredSlides={true}
                loop={true}
                slidesPerView={"auto"}
                spaceBetween={30}
                speed={700}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false
                }}
                keyboard={{
                    enabled: true
                }}
                mousewheel={{ enabled: true }}
                direction="horizontal"
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5
                }}
                pagination={{ dynamicBullets: true, clickable: true }}
                navigation={{
                    nextEl: "",
                    prevEl: "",
                    clickable: true
                }}
                modules={[Mousewheel, Keyboard, Autoplay, EffectCoverflow, Navigation, Pagination]}
                breakpoints={{
                    360: { slidesPerView: 1.1 },
                    420: { slidesPerView: 1.2 },
                    640: { slidesPerView: 1.7 },
                    768: { slidesPerView: 2.2 },
                    1024: { slidesPerView: 2.8 }
                }}
                className="rounded-lg relative z-0"
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index} className="rounded-lg overflow-hidden">
                        <div className="relative bg-white rounded-lg overflow-hidden">
                            <img src={slide.image} alt="" className="w-full h-48 md:h-52 lg:h-64 object-cover"/>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>  
    );
}

export default Slider;