import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faBoxOpen, faUser } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

function Navbar({active, setActive}) {

    const navItems = [
        { path: "/", label: "Home", icon: faHome },
        { path: "/search", label: "Search", icon: faSearch },
        { path: "/order", label: "My Order", icon: faBoxOpen },
        { path: "/account", label: "Account", icon: faUser },
    ];

    return (
    <div className="bg-white text-gray-400 flex items-center justify-around shadow-2xl fixed z-[999] bottom-0 w-full p-2 gap-1 border-t border-gray-100 rounded-t-xl">
            {navItems.map((item) => (
                <NavLink
                    to={item.path}
                    key={item.label}
                    onClick={() => {setActive(item.path)}}
                    className={`relative flex flex-col items-center justify-center text-sm transition-all px-4 py-2 w-full rounded-xl border-gray-200 ${active === item.path ? "text-gray-700 bg-slate-200" : "text-gray-400 hover:text-gray-600 hover:bg-slate-100"
                        }`}
                >
                    <FontAwesomeIcon icon={item.icon} className="text-xl relative z-10" />
                    <p className="relative z-10">{item.label}</p>
                </NavLink>
            ))}
        </div>
    );
}

export default Navbar;
