import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faSearch, faHeartCircleBolt, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Header({sidebar, setSidebar}) {
    return (
        <header className="fixed z-[999] bg-white text-gray-600 py-3 px-4 flex flex-col items-center justify-between shadow-xl w-full rounded-b-xl">
            <div className='bg-white text-gray-600 flex items-center justify-between w-full'>
                <div className="text-xl">
                    <button
                        className='hover:bg-slate-200 p-1 px-3 rounded-lg'
                        onClick={() => {setSidebar(!sidebar)}}
                    >
                        <FontAwesomeIcon icon={faBarsStaggered} className="cursor-pointer" />
                    </button>
                </div>

                <div className="text-xl font-semibold">
                    <Link to="/"
                        className=''
                    >Abhinay Mart</Link>
                </div>

                <div className="flex items-center justify-center gap-1 text-xl">
                    <button className='hover:bg-slate-200 text-red-500 p-1 px-3 rounded-lg'>
                        <FontAwesomeIcon icon={faHeartCircleBolt} className="cursor-pointer" />
                    </button>
                    <button className='hover:bg-slate-200 p-1 px-3 rounded-lg'>
                        <FontAwesomeIcon icon={faShoppingCart} className="cursor-pointer" />
                    </button>
                </div>
            </div>
            <div className='w-full px-1 pt-2 relative flex justify-center items-center'>
                <FontAwesomeIcon icon={faSearch} className='absolute left-4 my-auto top-2 bottom-0 pointer-events-none'/>
                <input type="search" name="" id="" placeholder='Search' className='w-full h-auto p-2 pl-10 bg-slate-100 border border-gray-200 outline-none focus:border-gray-400 rounded-lg'/>
            </div>
        </header>
    );
}

export default Header;
