import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./pages/Home";
import Account from "./pages/Account";

import NotFound from './components/404';
import Header from './components/Header';
import Navbar from './components/NavBar';
import Sidebar from './components/Sidebar';

import Password from './components/Password';

function App() {
  const password = "KR";
  const [inputPassword, setInputPassword] = useState("");
  const [isAuth, setIsAuth] = useState(false);

  const [activeNavBar, setActiveNavBar] = useState("/");
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    const storedPass = sessionStorage.getItem("authPass");
    if (storedPass === password) {
      setIsAuth(true);
    }
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (inputPassword === password) {
      sessionStorage.setItem("authPass", inputPassword);
      setIsAuth(true);
    } else {
      alert("Invalid Credentials")
      setInputPassword("");
      setIsAuth(false);
    }
  }

  const pages = [
    { path: "/", element: <Home /> },
    { path: "/account", element: <Account /> },
    { path: "*", element: <NotFound /> },
  ]
  
  if (!isAuth) {
    return (
      <Password handleLogin={ handleLogin } input={inputPassword} setInput={setInputPassword} />
    );
  } else {
    return (
      <Router>
        <Header sidebar={sidebar} setSidebar={setSidebar} />
        <Sidebar sidebar={sidebar} setSidebar={setSidebar} active={activeNavBar} setActive={setActiveNavBar} />
        <div className='pt-28 pb-20'>
          <Routes>
            {pages.map((page) => (
              <Route path={page.path} element={page.element} />
            ))}
          </Routes>
        </div>
        <Navbar active={activeNavBar} setActive={setActiveNavBar}/>
      </Router>
    );
  }
}

export default App;
