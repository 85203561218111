import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBoxOpen, faCartShopping, faGear, faHeart, faPhone, faTicket, faUser, faUserCircle, faWallet } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
// import { Link } from 'react-router-dom';

function Account() {
    const accountItems = [
        { path: "/profile", label: "Profile", icon: faUser },
        { path: "/order", label: "My Order", icon: faBoxOpen },
        { path: "/coupon", label: "Coupon", icon: faTicket },
        { path: "/favorite", label: "Favorite", icon: faHeart },
        { path: "/cart", label: "Cart", icon: faCartShopping },
        { path: "/balance", label: "Balance", icon: faWallet },
        { path: "/setting", label: "Setting", icon: faGear },
        { path: "/help", label: "Help Center", icon: faPhone },
        { path: "/logout", label: "Logout", icon: faArrowRightFromBracket },
    ];

    return (
        <div className="flex flex-col items-center mx-auto h-auto min-h-auto max-w-screen-xl p-4">
            <div className="flex flex-col items-center p-4 pt-20 mt-8 bg-slate-100 text-gray-700 justify-center rounded-xl gap-2 w-full relative">
                <div className='absolute -top-8'>
                    <FontAwesomeIcon icon={faUserCircle} className="text-7xl bg-white rounded-full" />
                    <div className="flex flex-col font-bold text-lg mt-2">
                        Hello KR!
                    </div>
                </div>
                {/* <hr className='mt-3 mb-1 w-full' /> */}
                <div className="flex flex-col gap-0 overflow-y-auto overflow-x-hidden w-full h-auto">
                    {accountItems.map((item) => (
                        <NavLink
                            to={item.path}
                            key={item.label}
                            onClick=''
                            className={`flex p-3 py-2 items-center rounded-lg gap-4 text-xl text-gray-500 hover:text-gray-600 border-b border-white hover:bg-white`}
                        >
                            <FontAwesomeIcon icon={item.icon} className="relative z-10 w-6" />
                            <p className="relative z-10">{item.label}</p>
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Account;
