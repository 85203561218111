import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/fontawesome-free-regular";

function Products() {
    const initProducts = [
        {
            id: 0,
            image: "https://media.istockphoto.com/id/1829241109/photo/enjoying-a-brunch-together.jpg?b=1&s=612x612&w=0&k=20&c=Mn_EPBAGwtzh5K6VyfDmd7Q5eJFXSHhGWVr3T4WDQRo=",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 1,
            image: "https://www.cnet.com/a/img/resize/69256d2623afcbaa911f08edc45fb2d3f6a8e172/hub/2023/02/03/afedd3ee-671d-4189-bf39-4f312248fb27/gettyimages-1042132904.jpg?auto=webp&fit=crop&height=675&width=1200",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 2,
            image: "https://cdn.sanity.io/images/cctd4ker/production/9dfaeda73538877faf9c927ec8c8d6863c2c2111-4800x3200.jpg?w=3840&q=75&fit=clip&auto=format",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 3,
            image: "https://www.bione.in/wp-content/uploads/2024/07/best-fermented-food.jpg",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 4,
            image: "https://assets.telegraphindia.com/telegraph/2023/Jul/1690269945_1-68.jpg",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 5,
            image: "https://www.nehascookbook.com/wp-content/uploads/2022/09/Punjabi-thali-WS.jpg",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 6,
            image: "https://clubmahindra.gumlet.io/blog/images/Paneer-Bhurji-resized.jpg?w=376&dpr=2.6",
            title: "This Is Title",
            price: "784",
            fav: false
        },
        {
            id: 7,
            image: "https://cdn0.weddingwire.in/article/4333/3_2/960/jpg/123334-maharaja-bhog-2.jpeg",
            title: "This Is Title",
            price: "784",
            fav: false
        },
    ];

    const [products, setProducts] = useState(initProducts);

    function handleFav(id) {
        const newProducts = products.map((product) => {
            if (product.id === id) {
                const updtaedProduct = {
                    ...product,
                    fav: !product.fav
                };
                return updtaedProduct;
            }
            return product;
        });
        setProducts(newProducts);
    }

    return (
        <div className="w-full pt-4">
            <h1 className="font-bold px-2">Products</h1>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 w-full overflow-auto no-scrollbar">
                {products.map((product, index) => (
                    <div className="flex justify-center items-center w-full p-1 relative">
                        <div className="flex flex-col justify-center w-full">
                            <div className="w-full flex justify-center relative">
                                <img src={product.image} alt={product.title} className="w-full object-cover aspect-square rounded-md" />
                                <button type="button" className="bg-white text-red-500 text-2xl py-1 px-2 rounded-lg absolute top-1 right-1 bg-opacity-50 backdrop-blur-sm" onClick={() => handleFav(product.id)}>
                                    <FontAwesomeIcon icon={product.fav ? faHeart : faHeartRegular} />
                                </button>
                            </div>
                            <span className="text-gray-400 text-sm font-semibold mt-1">{product.title + " " + index}</span>
                            <div className="text-md font-bold flex items-center tracking-wider"><span className="text-xs text-red-400 line-through decoration-2 pr-1">{index + product.price}</span>₹{product.price}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Products;