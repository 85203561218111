import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="flex justify-center items-center h-screen bg-blue-50">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-red-300">404</h1>
                <p className="text-xl text-gray-600">Page Not Found</p>
                <Link to="/" className='mt-4 inline-block bg-gray-600 text-white px-6 py-2 rounded hover:bg-gray-700'>
                Go To Home</Link>
            </div>
        </div>
    );
};

export default NotFound;
