import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faArrowRightFromBracket, faHome, faSearch, faUser, faUserCircle, faCartShopping, faGear, faPhone, faList } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faTelegram, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";

function Sidebar({ sidebar, setSidebar, active, setActive }) {
    const sideItems = [
        { path: "/", label: "Home", icon: faHome },
        { path: "/search", label: "Search", icon: faSearch },
        { path: "/order", label: "My Order", icon: faBoxOpen },
        { path: "/profile", label: "Profile", icon: faUser },
        { path: "/cart", label: "Cart", icon: faCartShopping },
        { path: "/category", label: "Category", icon: faList },
        { path: "/setting", label: "Setting", icon: faGear },
        { path: "/help", label: "Help Center", icon: faPhone },
        { path: "/logout", label: "Logout", icon: faArrowRightFromBracket }
    ];
    const bottomItems = [
        { path: "https://telegram.dog/abhinaymart7869", icon: faTelegram, style: "bg-gradient-to-bl from-blue-400 to-blue-600" },
        { path: "https://www.instagram.com/abhinaymart7869", icon: faInstagram, style: "bg-gradient-to-br from-pink-500 via-purple-500 to-yellow-500" },
        { path: "https://www.youtube.com/@abhinaymart7869", icon: faYoutube, style: "bg-gradient-to-r from-red-500 to-red-700" },
        { path: "https://www.instagram.com/abhinaymart7869", icon: faFacebook, style: "bg-gradient-to-tl from-blue-500 to-blue-800" },
        { path: "https://www.x.com/abhinaymart7869", icon: faXTwitter, style: "bg-gradient-to-tr from-black to-gray-600" }
    ];
    
    return (sidebar &&
        <div className={`fixed bg-gray-900 bg-opacity-30 backdrop-blur-sm w-full h-full z-[9999] flex p-4`} onClick={() => setSidebar(!sidebar)}>
            <div className={`bg-white w-full h-full max-w-xs rounded-3xl p-4 relative overflow-hidden flex flex-col justify-between`} onClick={(e) => e.stopPropagation()}>
                {/* <button
                    className='hover:text-gray-800 text-gray-600 p-2 py-1 pl-2 rounded-r-3xl absolute top-0 -right-9 text-2xl bg-white'
                    onClick={() => { setSidebar(!sidebar) }}
                >
                    <FontAwesomeIcon icon={faCircleXmark} className="cursor-pointer" />
                </button> */}
                <div className="overflow-hidden flex-grow flex flex-col">
                    <div className="flex p-4 bg-slate-200 text-gray-700 justify-between rounded-xl gap-2">
                        <div className="flex items-center gap-2">
                            <FontAwesomeIcon icon={faUserCircle} className="text-5xl"/>
                            <div className="flex flex-col font-bold text-lg">
                                Hello KR!
                            </div>
                        </div>
                        <button
                            className='hover:text-gray-800 text-gray-600 p-4 py-1 rounded-xl text-xl bg-slate-100 hover:bg-white'
                            onClick={() => { setSidebar(!sidebar) }}
                        >
                            <FontAwesomeIcon icon={faArrowRightFromBracket} className="cursor-pointer" />
                        </button>
                    </div>
                    <hr className="my-4" />
                    <div className="flex flex-col gap-2 overflow-x-hidden overflow-y-scroll w-full">
                        {sideItems.map((item) => (
                            <NavLink
                                to={item.path}
                                key={item.label}
                                onClick={() => { setActive(item.path) }}
                                className={`flex p-3 py-2 items-center rounded-lg gap-4 text-lg ${active === item.path ? "text-gray-700 bg-slate-200 font-bold" : "text-gray-500 hover:text-gray-600 hover:bg-slate-100"
                                    }`}
                            >
                                <FontAwesomeIcon icon={item.icon} className="relative z-10 w-6" />
                                <p className="relative z-10">{item.label}</p>
                            </NavLink>
                        ))}
                    </div>
                </div>
                <div className="w-full flex justify-center gap-2 border-t pt-2">
                    {bottomItems.map((item) => (
                        <NavLink
                            to={item.path}
                            key={item.label}
                            className={`flex p-2 justify-center items-center rounded-lg gap-4 text-2xl text-white ${item.style}`}
                        >
                            <FontAwesomeIcon icon={item.icon} className="relative z-10 w-6" />
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Sidebar;