import React from 'react';
// import { Link } from 'react-router-dom';
import Slider from '../components/Slider';
import Category from '../components/Category';
import Products from '../components/Products';

function Home() {
    return (
        <div className="flex flex-col items-center mx-auto h-auto min-h-screen max-w-screen-xl">
            <Slider />
            <Category />
            <Products />
        </div>
    )
}

export default Home;
